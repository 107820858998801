*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  /* font-family: "arial"; */
}
.max-width{
  max-width: 1000px;
  padding: 0 20px;
  margin: auto;
}
.max-width.cntr{
  justify-content: center;
  align-items: center;
  text-align: center;
}
.header{
  justify-content: right;
  text-align: right;
  height: 100px;
  padding: 50px 0;
  margin: 0 0 20px 0;
}
.logo{
  width: 200px;
}
.title{
  color: #0053a1;
  font-size: 30px;
  margin: 0 0 30px 0;
}
.title.cntr{
  text-align: center;
}
.title.cntr span{
  color: #242424;
  font-size: 14px;
  font-weight: normal;
}
.parag{
  padding: 10px 0;
  font-weight: 400;
  color:#242424;
}
.parag.indent{
  text-indent: 70px; 
}
.parag span{
  color: #0053a1;
}
.inner-center{
  width: 100%;
  text-align: center;
}
button{
  padding: 10px 30px;
  border-radius: 5px;
  border: none;
  background: #0053a1;
  color: #fff;
  outline:none;
  cursor: pointer;
  margin: 20px auto;
}
button .fa{
  margin-left: -12px;
  margin-right: 8px;
}
.form{
  margin: 70px 0 100px 0;
}
.form h1{
  font-size: 18px;
  text-align: center;
  color: #2e2e2e;
}
.form h2{
  color: #b5b5b5;
  font-size: 14px;
  text-align: center;
  padding: 10px 0 30px;
  border-bottom: solid 1px #dbdbdb;
  font-weight: normal;
}
.form h3{
  color: #2e2e2e;
  font-size: 14px;
  padding: 30px 0 10px;
  font-weight: 600;
  text-align: center;
}
.form .fields{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.fields .field{
  width: 50%;
  padding: 20px; 
}
.fields .field label{
  width: 100%;
  display: block;
  font-size: 14px;
}
.fields .field label span{
  color: #c40000;
}
.fields .field input{
  width: 70%;
  padding: 5px;
  margin: 10px 0;
  border: none;
  border-radius: 0;
  border-bottom: solid 1px #dbdbdb;
  outline: none
}
.fields .field input:focus{
  border-bottom: solid 1px #2e2e2e;
}
.fields .field p{
  color: #b5b5b5;
  font-size: 12px;
}
.fields .field p.error{
  color: #c40000;
}
.fields .field select{
  width: 70%;
  padding: 5px;
  margin: 10px 0;
  border: none;
  border-radius: 0;
  border-bottom: solid 1px #dbdbdb;
  outline: none
}
.form .danger{
  color: #004ec4;
  font-size: 12px;
  padding: 30px 20px;
}
.line{
  width: 100%;
  height: 1px;
  background: #dbdbdb;
}
.fields .field span.checkbox{
  display: block;
  width: auto;
  margin: -22px 0 10px 35px;
  font-size: 12px;
}
.fields .field input.checkbox{
  width: 20px;
  height: 20px;
  margin: 0 5px;
}
.success{
  text-align: center;
  margin: 60px 0;
}
.success i{
  font-size: 100px;
  color: #004ec4;
}

@media(max-width: 696px){
  .fields .field{
    width: 100%;
  }
}
